// FaqQuestions.tsx
import React, { useState } from "react";
import dropdown from "../../assets/faq/drop-down-blu.png";
import dropup from "../../assets/faq/faq-up.png";

import { categoryFAQs } from "../../utils/faq/faq";
interface FaqQuestionsProps {
  category: any;
}
export default function FaqQuestions({ faqs }: any) {
  const [expandedIndex, setExpandedIndex] = useState(null);
  const handleToggle = (index: any) => {
    setExpandedIndex((prevIndex: any) => (prevIndex === index ? null : index));
  };
  return (
    <>
      {faqs?.map((faq: any, index: number) => (
        <>
          <div
            key={index}
            className={`${
              expandedIndex === index
                ? "d-none"
                : "faq-question-box faq-question-text"
            }`}
          >
            {/* <p className="faq-question-text"></p> */}
            {faq.question}
            <img
              className="faq-drop-down-img"
              src={dropdown}
              alt="Dropdown"
              onClick={() => {
                handleToggle(index);
              }}
            />
          </div>

          {expandedIndex === index && (
            <div className="faq-answer-box">
              <p className="faq-question-text">{faq.question}</p>

              <div className="faq-question-line"></div>
              <div className="faq-answer-div">
                <p className="faq-question-text">{faq.answer}</p>
              </div>
              <img
                className="faq-drop-up-img"
                src={dropup}
                alt="Dropdown"
                onClick={() => {
                  handleToggle(index);
                }}
              />
            </div>
          )}
        </>
      ))}
    </>
  );
}
