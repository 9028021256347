import React from 'react'
import Button from '../general/button'

export default function FaqCategories({ category, isSelected, onSelect }: any) {
    const handleClick = () => {

        onSelect(category)
    }
    return (
        <Button text={category} active={isSelected} Click={() => { handleClick() }} />
        // <div className={`${isSelected ? 'faq-third-section-heading-main faq-third-section-text ' : 'faq-third-section-heading-main-inactive faq-third-section-text'}`} onClick={handleClick}>
        //     {category}
        // </div>
    )
}
