import React, { useState, useEffect } from 'react';
import "./OurValueCard.css";
import Line from "../../../../assets/About/OurValueLine.png";
import InnovationPic from "../../../../assets/About/InnovationPic.png";// @ts-ignore()
import BackImg from "../../../../assets/About/OurValueCardBack.svg"; // @ts-ignore()
import OverLayImage from "../../../../assets/About/OurValueCardOverlay.svg";

interface OurValueCardProps {
  title: string;
  image: any;
  radius: any;
  angle: any;
  x: any;
  y: any;
  totalOffset: any;
  parentContainerHeight: any;
}

const defaultProps: Partial<OurValueCardProps> = {
  title: "Student",
  image: InnovationPic,
};

const OurValueCard: React.FC<OurValueCardProps> = ({ title, image, angle, x, y, radius, totalOffset, parentContainerHeight }: OurValueCardProps) => {
  const [position, setPosition] = useState({ top: 50, left: 50 });

  useEffect(() => {
    const topPercentage = (y + totalOffset) / parentContainerHeight * 100;
    const leftPercentage = (x + totalOffset) / parentContainerHeight * 100;
    setPosition({ top: topPercentage, left: leftPercentage });
  }, [x, y, totalOffset, parentContainerHeight]);

  return (
    <div
      className="OurValueCard-Main"
      style={{
        position: "absolute",
        top: `${position.top}%`,
        left: `${position.left}%`,
        width: "fit-content",
        height: "fit-content",
        transition: "top 2s ease-in-out, left 2s ease-in-out",
      }}
    >
      <div className="OurValueCard-container">
        <div className="OurValueCard-BackGround">
          <img src={BackImg} className="OurValueCard-BackGround-Pic" alt="Background"></img>
          <img src={OverLayImage} className="OurValueCard-OverLay-Pic" alt="Overlay"></img>
        </div>
        <div className="OurValueCard-OverLay">
          <div className="OurValueCard-Image-div">
            <img src={image} className="OurValueCard-Image" alt="Card Image"></img>
          </div>
          <div className="OurValueCard-title-div">
            <p className="OurValueCard-title-text">{title}</p>
          </div>
          <div className="OurValueCard-Line-div">
            <img src={Line} className="OurValueCard-Line" alt="Line"></img>
          </div>
        </div>
      </div>
    </div>
  );
};

OurValueCard.defaultProps = defaultProps;
export default OurValueCard;
