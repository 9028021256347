// @ts-ignore
import { ContentBox } from "../../home";
import SmTeamCard from "../Cards/SmTeamCard/Index";
import Team1 from "../../../assets/About/Team1.png";
import Team2 from "../../../assets/About/Team2.png";
import Team3 from "../../../assets/About/Team3.png";
import Team4 from "../../../assets/About/Team4.png";
import LgTeamCard from "../Cards/LgTeamCard/Index";
import line from "../../../assets/home/FutureGenLine.png";
import "./OurTeam.css";
import { useState } from "react";
import Carousel from "../AboutCarousel/AboutCarousel";

const futureGenContent = {
  firstTitle: "Our Unique",
  firstTitle1: "The Unique",
  subFirstTitle: " Team",
  secondTitle:
    "Inherent limitations of the traditional model leave individual learning gaps unresolved ",
  subSecondTitle: "gaps unresolved",
  gradient: true,
  fontSize: 50,
  top: 0,
  lineTrue: true,
};

const SubTeam = {
  Person2: {
    title: "John Carter",
    description:
      "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team2,
  },
  Person3: {
    title: "John Carter",
    description:
      "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team3,
  },
  Person4: {
    title: "John Carter",
    description:
      "Nice Things improvement with ongoing assessments that automatically target your learning gaps.",
    designation: "Designation",
    image: Team4,
  },
}
const Team = {
  Person1: {
    title: "John Carter",
    description:
      "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
    designation: "Designation",
    image: Team1,
  },
};

const Person1 = {
  title: "John Carter",
  description:
    "Accelerate improvement with ongoing assessments that automatically target your learning gaps.",
  designation: "Designation",
  image: Team2,
};

const OurTeam = (props: any) => {

  const [visibleCards, setVisibleCards] = useState(0); // Index of the first visible card
  const cardsPerPage = 2; // Number of cards to display per page
  const totalCards = Object.values(SubTeam).length;
  const cardWidth = 50; // Adjust the width of each card in percentage

  const handleNext = () => {
    if (visibleCards < totalCards - cardsPerPage) {
      setVisibleCards(visibleCards + 1);
    } else {
      setVisibleCards(0); // Wrap around to the first cards
    }
  };

  const handlePrev = () => {
    if (visibleCards > 0) {
      setVisibleCards(visibleCards - 1);
    } else {
      setVisibleCards(totalCards - cardsPerPage); // Wrap around to the last cards
    }
  };

  // Calculate the transform property based on the visibleCards and cardWidth
  const transformValue = `translateX(-${visibleCards * (100 / cardsPerPage)}%)`;


  return (
    <div className="OurTeam-container">
      <div className="OurTeam-sub1">
        <div
          className="OurTeam-sub1-sub"
          data-aos="fade-left"
          data-aos-offset="400"
          data-aos-duration="3000"
          data-aos-easing="ease-in-shine"
        >
          <div className="OurTeam-sub1-sub1">
            <p className="OurTeam-sub1-text1-type1">
              {futureGenContent.firstTitle}
            </p>
            <p className="OurTeam-sub1-text1-type2">
              {futureGenContent.firstTitle1}
            </p>
            <p className="OurTeam-sub1-text2">
              {futureGenContent.subFirstTitle}
            </p>
          </div>
          <div className="OurTeam-sub1-sub2">
            <p
              className="OurTeam-sub1-text3-type1"
              data-aos="fade-right"
              data-aos-offset="400"
              data-aos-duration="3000"
              data-aos-easing="ease-in-shine"
            >
              {futureGenContent.secondTitle}
            </p>
            <p
              className="OurTeam-sub1-text3-type2"
              data-aos="fade-right"
              data-aos-offset="400"
              data-aos-duration="3000"
              data-aos-easing="ease-in-shine"
            >
              Reach the pinnacle of academic success with{" "}
              <span style={{ color: "#BD23A2" }}>OctiLearn’s</span> adaptive
              learning ecosystem.
            </p>
          </div>
          <div className="OurTeam-sub1-sub3">
            <img
              className="InspirationBehind-Line"
              src={line}
              data-aos="zoom-in"
              data-aos-offset="500"
              data-aos-duration="3000"
              data-aos-easing="ease-in-shine"
            />
          </div>
        </div>
      </div>
      <div
        className="OurTeam-sub2"
        data-aos="fade-left"
        data-aos-offset="400"
        data-aos-duration="3000"
        data-aos-easing="ease-in-shine"
      >
        <LgTeamCard {...Team.Person1} />
      </div>
      <div className="OurTeam-sub3" id="OurTeam-sub3-type1">
        <div data-aos="fade-up" data-aos-offset="400" data-aos-duration="1000" style={{width:"30%"}}>
        <SmTeamCard {...SubTeam.Person2} />
        </div>
        <div
          data-aos="fade-up"
          data-aos-offset="400"
          data-aos-duration="1000"
          data-aos-delay="700"
          style={{width:"30%"}}
        >
        <SmTeamCard {...SubTeam.Person3} />
        </div>
        <div
          data-aos="fade-up"
          data-aos-offset="400"
          data-aos-duration="1000"
          data-aos-delay="1000"
          style={{width:"30%"}}
        >
        <SmTeamCard {...SubTeam.Person4} />
        </div>
      </div>
      <div className="OurTeam-sub3" id="OurTeam-sub3-type2" style={{ transform: transformValue }}>
        <Carousel subTeam={SubTeam}/>
        </div>

    </div>
  );
};

export default OurTeam;
