import React from 'react'

export default function Button({ text, active, Click, isQuestion, type }: any) {
    return (
        <button
            type={type}
            onClick={Click}
            className={`${active ? 'faq-third-section-heading-main faq-third-section-text ' : 'faq-third-section-heading-main-inactive faq-third-section-text'} ${isQuestion ? 'question-button' : ''}`}> {text}</button >
    )
}
{/* <div className= onClick={handleClick}>
        //     {category}
        // </div> */}