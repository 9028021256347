import { useNavigate } from 'react-router-dom';
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
} from '../../../assets/icons';

import './style.css';
import { Link } from 'react-router-dom';

function Footer() {
  const navigate = useNavigate();

  return (
    <>
      <div className="horizontal-line"></div>
      <footer className="footer_container">
        <div className="py-4">
          <div className="footer-main-container">
            <div className="octi-img-social">
              <img
                className="octi-learn-logo"
                src="logo.svg"
                alt=""
                style={{
                  marginLeft: '-60px',
                  width: '100%',
                }}
              />
              <p className="my-4 experience-text">
                Experience the future of learning.
              </p>
              <div className="icons d-flex  justify-content-between align-items-center social-media-icon">
                <div className=" ">
                  <a href="https://twitter.com/OctiLearn" target="_blank">
                    <Twitter width={25} height={19} />
                  </a>
                </div>
                <div className=" ">
                  <a href="https://www.instagram.com/octilearn" target="_blank">
                    <Instagram width={20} height={20} />
                  </a>
                </div>

                <div className="">
                  <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                    <Facebook width={15} height={20} />
                  </a>
                </div>
                <div className="">
                  <a
                    href="https://www.linkedin.com/company/octilearn/"
                    target="_blank"
                  >
                    <Linkedin width={20} height={20} />
                  </a>
                </div>
                {/* <div className=" px-1">
                <a href="https://www.facebook.com/OctiLearn/" target="_blank">
                  <Facebook width={15} height={20} />
                </a>
              </div> */}
              </div>
            </div>

            <div className="parent-beta">
              <div className="child-beta">
                <Link
                  to={`${process.env.REACT_APP_SIGNUP_URL}`}
                  target="_blank"
                  style={{ color: 'white', textDecoration: 'none' }}
                >
                  <div className="border-div">
                    <div className="beta-container">
                      <div className="beta-container-1">
                        <img
                          className="inside-img"
                          src="/insideBorder.png"
                          alt="insideDiv"
                        />
                        <img src="beta.png" className="beta" alt="beta" />
                      </div>
                    </div>
                    <div className="text-footer">
                      <p className="apply-text">
                        Currently in Public Beta. Officially launching soon.
                      </p>
                    </div>
                  </div>
                </Link>

                {/* <a
                  className="beta-link"
                  href="https://airtable.com/appp24e4POBGM4X9o/shrr3sIvV2Wjr2osF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 
                </a> */}

                <p className="footer-para">
                  We are constantly improving the product and would love your
                  help. Join our beta and contribute to the future!
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
