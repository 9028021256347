import './style.css';
// import Home from "../../../pages/Home"

import { useEffect, useRef, useState } from 'react';
import { TagLine } from '../../../assets/icons';
import NeptuneElectronImage from './NeptuneElectronImage.png';
import UranusElectronImage from './UranusElectronImage.png';
import JupiterElectronImage from './JupiterElectronImage.png';
import EarthElectronImage from './EarthElectronImage.png';
import SunElectronImage from './SunElectronImage.png';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface Dimensions {
  galaxyHeight: number;
  galaxyTop: number;
  electron: number;
  sun: number;
  contentTop: number;
  title: number;
  tagline: number;
  para: number;
  btnW: number;
  btnH: number;
  btnMarginTop: number;
  orbitDimensions: {
    neptune: { width: number; margin: number };
    uranus: { width: number; margin: number };
    jupiter: { width: number; margin: number };
    earth: { width: number; margin: number };
    sun: { width: number; margin: number };
  };
}

const calculateDimension = (
  initialWidth: number,
  initialHeight: number
): number => {
  return (window.innerWidth / initialWidth) * initialHeight;
};

const initialDimensions: Dimensions = {
  galaxyHeight: calculateDimension(1800, 1755) + 100,
  galaxyTop: calculateDimension(1706, -144),
  electron: (80 * window.innerWidth) / 1689,
  sun: calculateDimension(10240, 1321.79),
  contentTop: calculateDimension(1498.89, 134) + 50,
  title: calculateDimension(1498.89, 62),
  tagline: calculateDimension(1498.89, 521),
  para: calculateDimension(1498.89, 20),
  btnMarginTop: calculateDimension(1498.89, 62),
  btnW: calculateDimension(1498.89, 190),
  btnH: calculateDimension(1498.89, 44),
  orbitDimensions: {
    neptune: {
      width: 1651 * (window.innerWidth / 1800),
      margin: (1651 * (window.innerWidth / 1800)) / 2,
    },
    uranus: {
      width: 1400 * (window.innerWidth / 1800),
      margin: (1400 * (window.innerWidth / 1800)) / 2,
    },
    jupiter: {
      width: 1159 * (window.innerWidth / 1800),
      margin: (1159 * (window.innerWidth / 1800)) / 2,
    },

    earth: {
      width: 859 * (window.innerWidth / 1800),
      margin: (859 * (window.innerWidth / 1800)) / 2,
    },
    sun: {
      width: 500 * (window.innerWidth / 1800),
      margin: (500 * (window.innerWidth / 1800)) / 2,
    },
  },
};

const HeroSection = (props: any) => {
  const [dimensions, setDimensions] = useState<Dimensions>(initialDimensions);
  const sunRef = useRef(null);
  const neptuneCounter = useRef(170);
  const uranusCounter = useRef(190);
  const jupiterCounter = useRef(210);
  const earthCounter = useRef(230);
  const sunCounter = useRef(260);

  const timers = useRef<NodeJS.Timeout[]>([]);

  const handleDimensions = () => {
    const newDimensions: Dimensions = {
      ...initialDimensions,
      galaxyTop: calculateDimension(1706, -144),
      galaxyHeight: calculateDimension(1800, 1755) + 100,
      electron: (80 * window.innerWidth) / 1689,
      sun: calculateDimension(10240, 1321.79),
      contentTop: calculateDimension(1498.89, 134) + 50,
      title: calculateDimension(1498.89, 62),
      tagline: calculateDimension(1498.89, 521),
      para: calculateDimension(1498.89, 20),
      btnMarginTop: calculateDimension(1498.89, 62),
      btnW: calculateDimension(1498.89, 190),
      btnH: calculateDimension(1498.89, 44),
      orbitDimensions: {
        neptune: {
          width: 1651 * (window.innerWidth / 1800),
          margin: (1651 * (window.innerWidth / 1800)) / 2,
        },
        uranus: {
          width: 1400 * (window.innerWidth / 1800),
          margin: (1400 * (window.innerWidth / 1800)) / 2,
        },
        jupiter: {
          width: 1159 * (window.innerWidth / 1800),
          margin: (1159 * (window.innerWidth / 1800)) / 2,
        },
        earth: {
          width: 859 * (window.innerWidth / 1800),
          margin: (859 * (window.innerWidth / 1800)) / 2,
        },
        sun: {
          width: 500 * (window.innerWidth / 1800),
          margin: (500 * (window.innerWidth / 1800)) / 2,
        },
      },
    };
    setDimensions(newDimensions);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleDimensions);
    return () => {
      window.removeEventListener('resize', handleDimensions);
    };
  }, []);

  const animFuns: Record<string, () => void> = {
    neptune: () => {
      const element = document.getElementsByClassName(
        `neptune-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${neptuneCounter.current}deg) skew(0deg, 0deg)`;

        // Counter-rotate the inner electron image
        // const electron = document.getElementsByClassName(
        //   `electron`
        // )[0] as HTMLElement;
        // if (electron) {
        //   electron.style.transform = `rotateZ(-${neptuneCounter.current}deg)`;
        // }

        neptuneCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.neptune);
      }
    },
    uranus: () => {
      const element = document.getElementsByClassName(
        `uranus-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${uranusCounter.current}deg) skew(0deg, 0deg)`;

        // Counter-rotate the inner electron image
        // const electron = document.getElementsByClassName(
        //   `electron`
        // )[0] as HTMLElement;
        // if (electron) {
        //   electron.style.transform = `rotateZ(-${uranusCounter.current}deg)`;
        // }

        uranusCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.uranus);
      }
    },
    jupiter: () => {
      const element = document.getElementsByClassName(
        `jupiter-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${jupiterCounter.current}deg) skew(0deg, 0deg)`;

        // Counter-rotate the inner electron image
        // const electron = document.getElementsByClassName(
        //   `electron`
        // )[0] as HTMLElement;
        // if (electron) {
        //   electron.style.transform = `rotateZ(-${jupiterCounter.current}deg)`;
        // }

        jupiterCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.jupiter);
      }
    },
    earth: () => {
      const element = document.getElementsByClassName(
        `earth-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${earthCounter.current}deg) skew(0deg, 0deg)`;

        // Counter-rotate the inner electron image
        // const electron = document.getElementsByClassName(
        //   `electron`
        // )[0] as HTMLElement;
        // if (electron) {
        //   electron.style.transform = `rotateZ(-${earthCounter.current}deg)`;
        // }

        earthCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.earth);
      }
    },
    sun: () => {
      const element = document.getElementsByClassName(
        `sun-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.transform = `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(${sunCounter.current}deg) skew(0deg, 0deg)`;

        // Counter-rotate the inner electron image
        // const electron = document.getElementsByClassName(
        //   `sun-electron`
        // )[0] as HTMLElement;
        // if (electron) {
        //   electron.style.transform = `rotateZ(-${sunCounter.current}deg)`;
        // }

        sunCounter.current += 0.15655634344;
        requestAnimationFrame(animFuns.sun);
      }
    },
  };

  const setTimer = (key: string, count: number) => {
    const timerId = setTimeout(() => {
      const element = document.getElementsByClassName(
        `${key}-orbit`
      )[0] as HTMLElement;
      if (element) {
        element.style.opacity = '1';
        animFuns[key]();
      }
    }, count);
    timers.current.push(timerId);
  };

  useEffect(() => {
    const cleanupTimers = timers.current;

    setTimeout(() => {
      const sun = sunRef.current;
      if (sun) {
        (sun as HTMLDivElement).style.opacity = '1';
      }
    }, 400);

    setTimeout(() => {
      const elements = document.getElementsByClassName(`electron`);
      if (elements) {
        for (let idx = 0; idx < elements.length; idx++) {
          const element = elements[idx] as HTMLElement;
          element.style.opacity = '1';
        }
      }
    }, 1200);

    let count = 600;
    const { orbitDimensions } = dimensions;
    const keys = Object.keys(orbitDimensions).reverse();
    keys.forEach((key) => {
      setTimer(key, count);
      count += 100;
    });

    return () => {
      cleanupTimers.forEach((timerId) => {
        clearTimeout(timerId);
      });
    };
  }, []);

  // const [shouldScroll, setShouldScroll] = useState(false);
  // const targetRef = useRef(null); // Create a ref for the target element in HeroSection

  // const handleScrollToElement = () => {
  //   setShouldScroll(true);
  // };

  const navigate = useNavigate();
  return (
    <div
      className="galaxy"
      style={{
        height: `${dimensions.galaxyHeight + 330}px`,
      }}
    >
      <div
        className="shade"
        style={{
          height: `${dimensions.orbitDimensions.neptune.width}px`,
          width: `${dimensions.orbitDimensions.sun.width}px`,
        }}
      ></div>

      <div className="content" style={{ top: `${dimensions.contentTop}px` }}>
        <h1 className="welcome-design">Ace Your IGCSE Journey with</h1>
        <div className="tagline_container">AI-Powered Adaptive Learning</div>
        <p className="pinnacle" style={{ fontSize: `${dimensions.para}px` }}>
          Interactive content, performance analytics, instant
          <br />
          academic support, and faster results – all in one place!
        </p>
        <div className="hero-button-margin">
          <Link to={`${process.env.REACT_APP_SIGNUP_URL}`} target="_blank">
            <button
              className="primary-button benefit-button1"
              // onClick={props?.scrollToComponent}

              style={{
                background: '#BD23A2',
              }}
            >
              Join Now
            </button>
          </Link>
        </div>
      </div>
      {Object.entries(dimensions.orbitDimensions).map(
        ([orbitName, orbitDims]) => (
          <div
            key={orbitName}
            className={`${orbitName}-orbit orbit`}
            style={{
              width: `${orbitDims.width}px`,
              height: `${orbitDims.width}px`,
              marginTop: `-${orbitDims.margin}px`,
              marginLeft: `-${orbitDims.margin}px`,
            }}
          >
            {/* <img              
              src={
                orbitName === 'neptune'
                  ? NeptuneElectronImage
                  : orbitName === 'uranus'
                  ? UranusElectronImage
                  : orbitName === 'jupiter'
                  ? JupiterElectronImage
                  : orbitName === 'earth'
                  ? EarthElectronImage
                  : SunElectronImage
              }
              className="electron" 
              alt={`${orbitName}-electron`}     
              style={{
                width: `${dimensions.electron}px`,
                height: `${dimensions.electron}px`,
                marginTop: `${(-31 * dimensions.electron) / 64.701}px`,
              }}
            /> */}

            <div
              className="electron"
              style={{
                width: `${dimensions.electron}px`,
                height: `${dimensions.electron}px`,
                // marginTop: `${((screenWidth > 576? -20:-20) * dimensions.electron) / 64.701}px`,
              }}
            >
              <img
                src={
                  orbitName === 'neptune'
                    ? NeptuneElectronImage
                    : orbitName === 'uranus'
                    ? UranusElectronImage
                    : orbitName === 'jupiter'
                    ? JupiterElectronImage
                    : orbitName === 'earth'
                    ? EarthElectronImage
                    : SunElectronImage
                }
                className="electron"
                alt={`${orbitName}-electron`}
                // style={{
                //   width: `${dimensions.electron}px`,
                //   height: `${dimensions.electron}px`,
                //   marginTop: `${(-31 * dimensions.electron) / 64.701}px`,
                // }}
              />
            </div>
          </div>
        )
      )}

      <img
        ref={sunRef}
        src="/octi-learn.svg"
        alt="OctiLearn"
        className="logo-img"
        // style={{ height: `${dimensions.sun}px` }}
        style={{ height: '8%' }}
      />
    </div>
  );
};

export default HeroSection;
