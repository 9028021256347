import Eclipse from "../../../../assets/About/Ellipse.png";
import ProblemPic from "../../../../assets/About/ProblemPic.png";
import Line from "../../../../assets/About/WhatWeDoLine.png";
import Line1 from "../../../../assets/About/WhatWeDoLine1.png";
import GradientLine from "../../../../assets/About/GradientLine.png";
import "./WhatWeDoCard.css";
interface WhatWeDoCardProps {
  title: string;
  description: string;
  image: any;
  linetrue: boolean;
  BackNumber: string;
}
const defaultProps: Partial<WhatWeDoCardProps> = {
  title: "Problem",
  description:
    "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain more practical",
  image: ProblemPic,
  linetrue: true,
  BackNumber: "1",
};
function WhatWeDoCard({
  title,
  description,
  image,
  linetrue,
  BackNumber,
}: WhatWeDoCardProps) {
  return (
    <div className="WhatWeDoCard-Container">
      <div className="WhatWeDoCard-OverLay">
        <div className="WhaWeDoCard-sub1">
          <div className="WhatWeDoCard-Img-Container">
            <img src={Eclipse} className="WhatWeDoCard-BackImg"></img>
            <img src={image} className="WhatWeDoCard-OverLayImg"></img>
          </div>
          {linetrue && (
            <div className="WhatWeDoCard-Line-Container">
                         <img className="WhatWeDo-Line" src={Line} />

            </div>
          )}
        </div>
        <div className="WhaWeDoCard-sub2">
          <div className="WhatWeDoCard-Title-Container">
            <p className="WhatWeDoCard-title">{title}</p>
          </div>
      
          <div className="WhatWeDoCard-SimpleLine-Container">
            <img className="WhatWeDoCard-SimpleLine" src={GradientLine} />
          </div>
          <div className="WhatWeDoCard-Description-Container">
            <p className="WhatWeDoCard-description">{description}</p>
          </div>
        </div>
      </div>
      <div className="WhatWeDoCard-Background">
        <p className="WhatWeDoCard-Background-Text">{BackNumber}</p>

      </div>
    </div>
  );
}

WhatWeDoCard.defaultProps = defaultProps;
export default WhatWeDoCard;
