import React, { useEffect, useRef, useState } from "react";// @ts-ignore
import  "./About.css";
import HeroSection from "../../components/About/HeroSection/Index";
import InspirationBehind from "../../components/About/InspirationBehind/Index";
import OurTeam from "../../components/About/OurTeam/Index";
import WhatWeDo from "../../components/About/WhatWeDo/Index";
import OurValue from "../../components/About/OurValue/Index";

export default function About(){
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const handleOverlayIntersection = (entries: any) => {
        entries.forEach((entry: any) => {
          if (entry.isIntersecting) {
            setOverlayVisible(true);
          } else {
            setOverlayVisible(false);
          }
        });
      };
    useEffect(() => {
        const options = {
          root: null,
          rootMargin: "0px",
          threshold: 0.01,
        };
    
        const observer = new IntersectionObserver(
          handleOverlayIntersection,
          options
        );
    
        if (containerRef.current) {
          observer.observe(containerRef.current);
        }
    
        return () => {
          if (containerRef.current) {
            observer.unobserve(containerRef.current);
          }
        };
      }, []);
    return(
        <div className="About-Container">
            <div className="About-HeroSection">
                <HeroSection/>
            </div>
            <div className="About-InspirationBehind">
                <InspirationBehind/>
            </div>
           <div className="About-OurTeam">
            <OurTeam/>
           </div>
           <div className="About-WhatWeDo">
            <WhatWeDo/>
           </div>
           <div className="About-OurValue" ref={containerRef}>
            {isOverlayVisible&&(
            <OurValue/>
            )}
           </div>


        </div>

        
    );


}