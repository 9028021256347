import React, { useState } from "react";
import "./Contact-us.css";
import manStanding from "../../assets/contact-us/man-standing.png";
import Button from "../../components/general/button";
import Domimage from "../../assets/contact-us/dom.png";
import extDomimage from "../../assets/contact-us/Group.png";
import { roles } from "../../utils/faq/faq";
import circle from "../../assets/faq/circle.png";
import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import axios from "axios";
import { toast } from "react-toastify";
export default function ContactUs() {
  const [selectedRole, setSelectedRole] = useState("student");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const SubmitForm = async (e: any) => {
    e.preventDefault();

    if (!name || !email || !selectedRole || !number || !message) {
      toast.error("Please Fill all input fields");
      return;
    }

    // Additional validation for email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please provide a valid email address");

      return;
    }

    const data = {
      name: name,
      email: email,
      role: selectedRole,
      number: number,
      question: message,
    };

    try {
      const SERVER_URL = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(`${SERVER_URL}user/contact`, data);
      if (response.data) {
        toast.success(response.data);
        setEmail("");
        setName("");
        setNumber("");
        setMessage("");
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };
  return (
    <div className="contact-us-parent">
      <div className="contact-us-hero-section">
        <div className="">
          <div>
            <div className="contact-us-hero-headings-container">
              <p className="contact-us-hero-heading-1">We are here!</p>

              <p className="contact-us-hero-heading-colored">
                Let’s Talk about it
              </p>
            </div>
            <div>
              <p className="contact-us-hero-para-1">
                Our door is Always open for a good cup of discussion
              </p>
              <p className="contact-us-hero-para-2">
                By addressing individual learning gaps with innovative
                technology. Our aim is to build the world's most intelligent
                adaptive learning ecosystem, one that can be mapped onto any{" "}
                <span className="contact-us-k12">K-12</span> curriculum. Our
                inspiration lies in the possibility of tackling e Our
                inspiration lies in the possibility of tackling e
              </p>
            </div>
            <div className="contact-us-top-btn-div">
              <Button text="Share Your Excitemnt with us" isQuestion={true} />
            </div>
          </div>

          <div className="contact-us-img-container">
            {/* <img src={manStanding} /> */}
          </div>
          <img src={manStanding} className="contact-us-headphones-img" />
        </div>
      </div>
      <div className="contact-us-section-2">
        <div className="contact-us-section-2-info-side">
          <div className="contact-us-section-2-info-side-container">
            <p className="contact-us-section-2-info-side-p1">
              We Are Revolutionizing
            </p>
            <p className="contact-us-section-2-info-side-p2">
              Student Learning
            </p>
            <p className="contact-us-section-2-info-side-p3">
              By addressing individual learning gaps with innovative technology.
            </p>
          </div>
          <img src={Domimage} className="dom-img" />
        </div>
        <div className="contact-us-section-2-form-side">
          <form
            className="contact-us-section-2-container"
            onSubmit={SubmitForm}
          >
            <p className="contact-us-label-main">
              Please fill out the information to proceed
            </p>

            <input
              placeholder="Your Full Name"
              required
              className="contact-us-input"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <div className="contact-us-section2-roles-div">
              <p className="contact-us-label-select-role">Select Your Role</p>
              <div className="contact-us-roles-main-container">
                {roles.map((role) => {
                  return (
                    <div
                      className={`contact-us-section2-roles-container ${
                        role.name != selectedRole
                          ? "contact-us-not-selected-card "
                          : "contact-us-selected-card"
                      }`}
                      onClick={() => {
                        setSelectedRole(role.name);
                      }}
                    >
                      <div className="contact-us-section2-roles-image-container">
                        <img
                          src={circle}
                          className="contact-us-section2-roles-circle"
                        />
                        <img
                          className="contact-us-section2-role-img"
                          src={role.img}
                        />
                      </div>
                      <p className="contact-us-section2-roles-title">
                        {role.text}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <input
              placeholder="Email Address"
              required
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              className="contact-us-input"
            />
            <PhoneInputWithCountrySelect
              placeholder="Please add phone number with country code"
              defaultCountry="PK"
              onChange={(value: any) => {
                setNumber(value);
              }}
            />

            <textarea
              className="input-query"
              required
              placeholder="Write your query we will happy to reach you out!"
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
            <div className="contact-us-btn-div">
              <Button
                Click={SubmitForm}
                type="submit"
                text="Send Query Now"
                isQuestion={true}
              />
            </div>
          </form>
          <img src={extDomimage} className="dom-img-bg" />
        </div>
      </div>
    </div>
  );
}
