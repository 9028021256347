import React from "react";
import img2 from "../../../../assets/About/Frame2.png"; // @ts-ignore
import CardBack from "../../../../assets/About/BackImg2.svg"; // @ts-ignore
import CardBack1 from "../../../../assets/About/BackImg4.svg"; // @ts-ignore
import "./SmTeamCard.css";
import Insta from "../../../../assets/About/Insta.png";
interface SmTeamCardProps {
  title: string;
  image: any;
  designation: string;
  description: string;
}
const defaultProps: Partial<SmTeamCardProps> = {
  title: "John",
  description: "",
  designation: "",
  image: {},
};
function SmTeamCard({
  title,
  image,
  description,
  designation,
}: SmTeamCardProps) {
  return (
    <div className="SmTeamCard-Container">
      <div className="SmTeamCard-sub1">
        <img src={img2} className="SmTeamCard-Img2" />
        <img src={image} className="SmTeamCard-Img" />
      </div>
      <div className="SmTeamCard-sub2" style={{ backgroundColor: "black" }}>
        <div className="SmTeamCard-sub2-sub1">
          <img src={CardBack} className="SmTeamCard-sub2-BackGround-Pic1" />
          <img src={CardBack1} className="SmTeamCard-sub2-BackGround-Pic2" />
        </div>
        <div className="SmTeamCard-sub2-sub2">
          <div className="SmTeamCard-sub2-title">
            <p className="SmTeamCard-sub2-title-text">{title}</p>

            <a className="SmTeamCard-Insta-Link-type1">
              <img className="SmTeamCard-Insta-Link-Pic" src={Insta} />
            </a>
          </div>
          <div className="SmTeamCard-sub2-desgination">
            <p className="SmTeamCard-sub2-desgination-text">{designation}</p>
          </div>
          <div className="SmTeamCard-Insta-Link-type2">
            <a>
              <img className="SmTeamCard-Insta-Link-Pic" src={Insta} />
            </a>
          </div>
          <div className="SmTeamCard-sub2-description">
            <p className="SmTeamCard-sub2-description-text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
SmTeamCard.defaultProps = defaultProps;
export default SmTeamCard;
