import { useEffect, useRef } from "react";
import { IterativeIcon } from "../../../assets/icons/";

import "./style.css";

  const iterativetextContainer = ({ delay,style , offset, pointnum, text, textbr,className,textClassname}: any) => {
    return (
      <div data-aos="zoom-in" style={style} data-aos-delay={delay} data-aos-offset={offset} className={className}>
        <div className="point-1-container">
          <div className="text-point">{pointnum}.</div>
        </div>
        <h3 className={` m-0 ${textClassname}`}>{text} {textbr && <><br />{textbr}</>} </h3>

        
      </div>
    )
  }

export default iterativetextContainer;
