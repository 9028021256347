// @ts-ignore
import BackImg from "../../../../assets/About/BackImg1.svg";// @ts-ignore
import BackImg1 from  "../../../../assets/About/BackImg3.svg";
import Frame from "../../../../assets/About/Frame1.png";
import "./LgTeamCard.css";
import Insta from "../../../../assets/About/Insta.png";
interface LgTeamCardProps {
  title: string;
  designation: string;
  description: string;
  image: any;
}
const defaultProps = {
  title: "",
  designation: "",
  description: "",
  image: {},
};

function LgTeamCard({
  title,
  designation,
  description,
  image,
}: LgTeamCardProps) {
  return (
    <div className="LgTeamCard-container">
      <div className="LgTeamCard-sub1">
        <img src={image} className="LgTeamCard-Img1" />
        <img src={Frame} className="LgTeamCard-Img2" />
      </div>
      <div className="LgTeamCard-sub2-type1">
        <div className="LgTeamCard-sub2-sub1">
          <img src={BackImg} className="LgTeamCard-MainBackGround-Pic" />
        </div>
        <div className="LgTeamCard-sub2-sub2">
          <div className="LgTeamCard-sub2-title">
            <p className="LgTeamCard-sub2-title-text">{title}</p>
            <a style={{display:"flex",alignItems:"center"}}><img src={Insta} className="LgTeamCard-sub2-title-pic"/></a>
          </div>
          <div>
            <p className="LgTeamCard-sub2-designation-text">{designation}</p>
          </div>
          <div className="LgTeamCard-sub2-description">
            <p className="LgTeamCard-sub2-description-text">{description}</p>
          </div>
        </div>
      </div>


      <div className="LgTeamCard-sub2-type2">
        <div className="LgTeamCard-sub2-sub1">
          <img src={BackImg1} className="LgTeamCard-MainBackGround-Pic" />
        </div>
        <div className="LgTeamCard-sub2-sub2">
          <div className="LgTeamCard-sub2-title">
            <p className="LgTeamCard-sub2-title-text">{title}</p>
            <a style={{display:"flex",alignItems:"center"}}><img src={Insta} className="LgTeamCard-sub2-title-pic"/></a>
          </div>
          <div className="LgTeamCard-sub2-designation">
            <p className="LgTeamCard-sub2-designation-text">{designation}</p>
          </div>
          <div className="LgTeamCard-sub2-description">
            <p className="LgTeamCard-sub2-description-text">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
LgTeamCard.defaultProps = defaultProps;
export default LgTeamCard;
