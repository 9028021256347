import './header.css';


interface Props {
  title: string,
  coloredTitle?: string,
  subtitle: string,
  subWidth?: string
  titleFontSize: string
  subtitleFontSize?: string
  classname?: string
  titleClassName?: string
  subtitleClassName?: string
  offset: string
}

function NewHeader({ title, coloredTitle,titleClassName,subtitleClassName, subtitle, subWidth, classname, offset }: Props) {
  return (
    <div className='header-main-container'>
      <div className='header-title-container' data-aos="fade-up" data-aos-delay="200" data-aos-offset={offset} >
        <div className={`header-title ${titleClassName}`} >
          {title}
          <div className={`colored-title ${titleClassName}`}>
            {coloredTitle}
          </div>
        </div>
      </div>
      <div className={`subtitle-container ${subtitleClassName}`} style={{ maxWidth: subWidth }} data-aos="fade-up"
        data-aos-delay="900" data-aos-offset={offset}>
        {subtitle}
      <div className="tradional-bottom-line-new"></div>
      </div>
    </div>
  );
}

export default NewHeader;
