import { Button } from "../../UI";
import { ContentBox } from "../../home";
import "./OurValue.css";
import Line from "../../../assets/About/OurValueLine.png";
import OurValueCard from "../Cards/OurValueCard/Index";
import StudentCentric from "../../../assets/About/StudentCentricPic.png"; // @ts-ignore()
import InnovationPic from "../../../assets/About/InnovationPic.png";
import CouragePic from "../../../assets/About/CouragePic.png";
import PreserverancePic from "../../../assets/About/PreserverancePic.png";
import GrowthPic from "../../../assets/About/GrowthPic.png";
import ExcellencePic from "../../../assets/About/ExcellencePic.png";
import AuthenticityPic from "../../../assets/About/AuthenticityPic.png"; // @ts-ignore()
import Eclipse from "../../../assets/About/Ellipse.svg";
import { useEffect, useRef, useState } from "react";

const OurValues: any = {
  Value1: {
    title: "Preserverance",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: PreserverancePic,
  },
  Value2: {
    title: "Growth Mindset",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: GrowthPic,
  },
  Value3: {
    title: "Excellence",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: ExcellencePic,
  },
  Value4: {
    title: "Authenticity",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: AuthenticityPic,
  },
  Value5: {
    title: "Innovation",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: InnovationPic,
  },
  Value6: {
    title: "Student Centric",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: StudentCentric,
  },
  Value7: {
    title: "Courage",
    description:
      " Exasperated with the inflexibility, uniformity, and in effectivenessof traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic",
    image: CouragePic,
  },
};

const OurValue = (props: any) => {
  const div = 360 / 7;
  const offsetToChildCenter = 0;
  const [containerWidth, setContainerWidth] = useState(0);
  const [title, setTitle] = useState("Our Value");
  const [description, setDescription] = useState("Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic");
  const containerRef = useRef<HTMLDivElement | null>(null);
  const offsetToParentCenter = containerWidth / 2;
  const totalOffset = offsetToParentCenter - offsetToChildCenter;
  const radius = containerWidth - 0.32 * containerWidth;
  const children: any = [];
  Object.keys(OurValues).forEach((key) => {
    const value = OurValues[key];
    const angle = div * parseInt(key.substring(5)) - 12;
    const y = Math.sin(angle * (Math.PI / 180)) * radius;
    const x = Math.cos(angle * (Math.PI / 180)) * radius;
    children.push(
      <div
        className={`OurValueCard${key.substring(5)}`}
        key={key}
        onMouseEnter={() =>
          handleHover(OurValues[key].title, OurValues[key].description)
        }
      >
        <OurValueCard
          key={key}
          x={x}
          y={y}
          radius={radius}
          totalOffset={totalOffset}
          parentContainerHeight={containerWidth}
          {...value}
        />
      </div>
    );
  });
  const handleHover = (title: any, description: any) => {
    setTitle(title);
    setDescription(description);
  };
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }

  }, []);

  return (
    <div className="OurValue-container">
      <div className="OurValue-sub1" ref={containerRef}>
        <div >{children}</div>
        <div className="OurValue-BackSub">
          <img src={Eclipse} className="OurValue-BackSub-Pic" alt="Eclipse" />
        </div>
        <div className="OurValue-sub1-Overlay">
          <div className="OurValue-title-div">
            <p className="OurValue-title-text">{title}</p>
          </div>
          <div className="OurValue-description-div">
            <p className="OurValue-description-text">{description}</p>
          </div>
          <div className="OurValue-Image-div">
            <img src={Line} className="OurValue-Image-img" alt="Line" />
          </div>
          <div className="OurValue-Button-div">
            <a href="" target="_blank" rel="noopener noreferrer">
              <button className="OurValue-button">Let's Get Started</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurValue;
