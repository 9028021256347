import React from "react";
import "./HeroSection.css";// @ts-ignore()
import Img from "../../../assets/About/HeroSection.svg";
import { Button } from "../../UI";

const HeroSection = (props: any) => {
  return (
    <div className="HeroSection-Container">
      <div className="HeroSection-sub1">
        <div className="HeroSection-text1-container">
        <p className="HeroSection-text1"  data-aos="fade-right" data-aos-duration="2500" data-aos-offset="0" data-aos-easing="ease-in-shine">
            We are revolutionising the way{" "}
            <span className="HeroSection-text1-gradient">
              Student Learn and succeed
            </span>
          </p>
        </div>
        <div className="HeroSection-text2-container">
        <p className="HeroSection-text2"  data-aos="fade-left"data-aos-duration="2500" data-aos-offset="0" data-aos-easing="ease-in-shine">
            By addressing individual learning gaps with innovative technology.
            Our aim is to build the world's most intelligent adaptive learning
            ecosystem, one that can be mapped onto any{" "}
            <span style={{ color: "#E015A2" }}>K-12</span> curriculum. Our
            inspiration lies in the possibility of tackling educational
            disparities and empowering all learners in the world to realize
            their dreams.
          </p>
        </div>
        <div className="HeroSection-Button-Div" data-aos="fade-up" data-aos-duration="2500" data-aos-offset="0" data-aos-easing="ease-in-shine">
          <button className="HeroSection-button">Explore More</button>
  
        </div>
      </div>
      <div className="HeroSection-sub2">
        <div className="HeroSecion-Img-Container">
          <img className="Hero-Section-Img" src={Img} data-aos="fade-down" data-aos-duration="2500" data-aos-offset="10" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
