import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import SmTeamCard from '../Cards/SmTeamCard/Index';

interface SubTeamPerson {
  title: string;
  description: string;
  designation: string;
  image: string;
}

interface SubTeam {
  [key: string]: SubTeamPerson;
}

interface CarouselProps {
  subTeam: SubTeam;
}

const responsive = {
  0: { items: 2 },
};

const renderDotsItem = ({ isActive }: { isActive: boolean }) => {
  return isActive ? (
    <div
      style={{
        width: '70px',
        height: '7px',
        borderRadius: '7px',
        backgroundColor: '#D231A6',
        margin: '2px',
      }}
    ></div>
  ) : (
    <div
      style={{
        width: '7px',
        height: '7px',
        borderRadius: '7px',
        backgroundColor: '#D231A6',
        margin: '2px',
      }}
    ></div>
  );
};

const Carousel: React.FC<CarouselProps> = ({ subTeam }) => {
  const items = Object.keys(subTeam).map((key) => (
    <div style={{ marginRight: '5px' }} key={key}>
      <SmTeamCard {...subTeam[key]} />
    </div>
  ));

  return (
    <AliceCarousel
      items={items}
      responsive={responsive}
      renderDotsItem={renderDotsItem}
      disableButtonsControls
      controlsStrategy="alternate"
    />
  );
};

export default Carousel;
