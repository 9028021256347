const roles = [
  {
    name: "student",
    text: `Student`,
    img: require("../../assets/faq/student.png"),
  },
  {
    name: "parent",
    text: `Parent`,
    img: require("../../assets/faq/parent.png"),
  },
  {
    name: "company",
    text: `Company`,
    img: require("../../assets/faq/company.png"),
  },
];

const categories = {
  student: ["Ecosystem", "Application", "Ambassador Program", "Internship"],
  parent: [
    "Parent 1",
    "Parent 2",
    "Parent 3",
    "Parent 4",
    "Parent 5",
    "Parent 6",
  ],
  company: ["company 1", "company 2", "company 3", "company 4"],
};

// const categories = [

//     'Ecosystem',
//     'Application',
//     'Ambassador Program',
//     'Internship',
//     'Application'
// ];

const categoryFAQs = {
  Ecosystem: [
    {
      question: "What components make up the ecosystem?",
      answer:
        "TExasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain.",
    },
    {
      question: "How can I contribute to the development of the ecosystem?",
      answer:
        "Exasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain.",
    },
    {
      question: "Are there any partnerships within the ecosystem?",
      answer: "Yes, there are partnerships with...",
    },
    {
      question: "What components make up the ecosystem?",
      answer:
        "TExasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain.",
    },
    {
      question: "What components make up the ecosystem?",
      answer:
        "TExasperated with the inflexibility, uniformity, and ineffectiveness of traditional teaching methods, Abubakar adopted a systems-thinking approach. He sought to develop a dynamic platform to improve student learning—a setting where students could not only learn more effectively but also gain.",
    },
  ],
  Application: [
    {
      question: "How do I submit an application?",
      answer: "You can submit your application by...",
    },
    {
      question: "What are the key requirements for the application process?",
      answer: "The key requirements include...",
    },
    {
      question: "Is there a deadline for submitting applications?",
      answer: "The deadline for applications is...",
    },
  ],
  "Ambassador Program": [
    {
      question: "What benefits do ambassadors receive in the program?",
      answer: "Ambassadors receive benefits such as...",
    },
    {
      question: "How can I become an ambassador?",
      answer: "To become an ambassador, you need to...",
    },
    {
      question:
        "Are there any responsibilities associated with being an ambassador?",
      answer: "Yes, ambassadors have responsibilities like...",
    },
  ],
};

export { roles, categories, categoryFAQs };
