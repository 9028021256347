import React, { useEffect, useState } from "react";
import "./Faq.css";
import virtual_student from "../../assets/faq/virtual-studies.png";
import { categories, categoryFAQs, roles } from "../../utils/faq/faq";
import RolesCardFaq from "../../components/faq/rolescard";
import studentStanding from "../../assets/faq/student-standing.png";
import laptop from "../../assets/faq/laptop.png";
import FaqCategories from "../../components/faq/faqcategories";
import FaqQuestions from "../../components/faq/faqquestions";
import Button from "../../components/general/button";
import { useNavigate } from "react-router-dom";

export default function Faq() {
  const [selectedCategory, setSelectedCategory] = useState("Ecosystem");
  const [selectedRole, setSelectedRole] = useState("student");
  const navigate = useNavigate();

  const handleSelect = (category: any) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    // setSelectedCategory((categories as any)[selectedRole][0]);
    setSelectedCategory(selectedCategory);
  }, [selectedRole]);

  return (
    <div className="parent-div">
      <div className="FrequentlyAsked">
        <h2 className="frequently-asked-heading">Frequently Asked</h2>
        <h2 className="colored-question">Questions</h2>
      </div>

      <div className="frequentlyAskedParagraph">
        <p className="paragraph">
          By addressing individual learning gaps with innovative technology. Our
          aim is to build the world's most intelligent adaptive learning
          ecosystem, one that can be mapped onto any{" "}
          <span className="K-12-colored"> K-12 </span> curriculum. Our
          inspiration lies in the possibility of tackling educational
          disparities and empowering all learners in the world to realize their
          dreams.
        </p>
      </div>
      <div className="query-button-container">
        <Button
          text="Start Asking Query"
          active={false}
          Click={() => {
            navigate("/contact-us");
          }}
          isQuestion={true}
        />
        {/* <button className="question-button">Start Asking Query</button> */}
      </div>
      <div className="student-image-div">
        <img
          className="image-student"
          alt="studies"
          src={virtual_student}
        ></img>
      </div>

      <div className="faq-second-section-main">
        <div className="faq-second-section-inner">
          <div className="faq-second-section-first-div">
            <p>
              We offers answers to all of your question. We respect our
              audience.
            </p>
          </div>
          <div className="faq-second-section-second-div">
            {roles.map((role, index) => {
              return (
                <RolesCardFaq
                  index={index}
                  role={role}
                  roleClicked={({ name }: any) => {
                    setSelectedRole(name);
                    console.log("selected", (categories as any)[name][0]);
                  }}
                  selectedrole={selectedRole}
                />
              );
            })}
          </div>
        </div>
      </div>

      {/* <div second-section className="second-section-main">


                <div className="second-section-first-container" >
                    <p className="second-section-text">We offers answers to all of your question. We respect our audience.</p>
                </div>
                <div className="second-section-roles-main">

                    {roles.map((role) => {
                        return <RolesCardFaq role={role} roleClicked={({ name }: any) => {
                            setSelectedRole(name);
                            console.log('selected', (categories as any)[name][0]);


                        }} selectedrole={selectedRole} />
                    })}


                </div>

            </div> */}
      {/* 3rd section */}

      <div className="faq-third-section">
        <div>
          <div className="faq-third-container">
            <div className="faq-headings-box ">
              {/* (categories as any)[selectedRole] for dynamic faqs according to role  */}
              {(categories as any)["student"].map((category: any) => {
                return (
                  <FaqCategories
                    category={category}
                    onSelect={(category: any) => {
                      handleSelect(category);
                    }}
                    isSelected={category === selectedCategory}
                  />
                );
              })}
            </div>
          </div>

          <div className="faq-questions-wrapper">
            <div>
              {selectedCategory && (
                <FaqQuestions faqs={(categoryFAQs as any)[selectedCategory]} />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 4th section */}
      <div>
        <div className="faq-4th-section">
          <div>
            <img src={studentStanding} className="faq-student-standing-img" />
          </div>
          <div className="faq-4th-section-text-box">
            <div className="faq-4th-section-text-heading">
              Join OctiLearn Today!
            </div>
            <p className="faq-4th-section-text">
              Outdated government systems have been in the spotlight as we
              struggled with a global pandemic.
            </p>

            <div className="faq-4th-line-div">
              <p className="faq-4th-line"></p>
            </div>
            <button className="faq-4th-btn">Get In</button>
          </div>
          <div>
            <img src={laptop} className="faq-4th-section-laptop-img " />
          </div>
        </div>
      </div>
    </div>
  );
}
